import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/Seo"
import Timeline from "../components/timeline"
import Hello from "../components/hello"
import Bye from "../components/footer"

import { IoIosArrowUp } from "react-icons/io"
import ScrollToTop from "react-scroll-up"

// const IndexPage = ({ data }) => (
const IndexPage = ({ data }) => {
  // console.log(data)

  return (
    <Layout>
      <Seo title="Hello" />
      <Hello />
      <Timeline projekty={data} />
      <Bye />
      <ScrollToTop showUnder={160}>
        <span>
          <IoIosArrowUp />
        </span>
      </ScrollToTop>
    </Layout>
  )
}

export const query = graphql`
  query {
    projects: allMarkdownRemark(
      sort: { fields: frontmatter___order, order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            siteUrl
            siteName
            createdAt
            subtitle
            iconLogo {
              childImageSharp {
                gatsbyImageData
              }
            }
            infoArch
            infoDes
            screenShot {
              childImageSharp {
                gatsbyImageData(height: 150)
              }
            }
            techStack
            githubUrl
          }
        }
      }
    }
  }
`

export default IndexPage
