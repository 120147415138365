import React from 'react';
import '../components/timeline.css';

const footer = () => {
	return (
		<footer>
			<h3 className="swap">
				<span className="swap__visible">contact me</span>
				<span className="swap__invisible">if you like what you see</span>
			</h3>
			<p className="gradientText">
				<a href="mailto: piotrend@gmail.com" className="gradientText">
					piotrend@gmail.com
				</a>
			</p>
		</footer>
	);
};

export default footer;
