import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { Timeline, TimelineEvent } from "react-event-timeline"
import "../components/timeline.css"

import { FaGithub } from "react-icons/fa"
import { FiLayers } from "react-icons/fi"
import { FaPaperPlane } from "react-icons/fa"

const ProjectTimeline = ({ projekty }) => {
  // console.log(projekty.projects.edges)

  const element = projekty.projects.edges.slice(0, -2).map(el => (
    // const element = projekty.projects.edges.map(el => (
    <TimelineEvent
      key={el.node.frontmatter.siteName}
      title={
        <a
          href={el.node.frontmatter.siteUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="borderLeftRight gradientText"
        >
          {el.node.frontmatter.siteName}
        </a>
      }
      titleStyle={titleStyle}
      subtitle={el.node.frontmatter.subtitle}
      createdAt={el.node.frontmatter.createdAt}
      // icon={
      //   <GatsbyImage
      //     image={getImage(el.node.frontmatter.iconLogo)}
      //     style={timelineLogo}
      //     alt={el.node.frontmatter.githubUrl}
      //   />
      // }
      icon={
        <GatsbyImage
          image={getImage(el.node.frontmatter.iconLogo)}
          style={timelineLogo}
          alt={el.node.frontmatter.subtitle}
        />
      }
      iconStyle={iconStyle}
      bubbleStyle={bubbleStyle}
      contentStyle={contentStyle}
    >
      {el.node.frontmatter.infoArch ? <h4>Information architecture</h4> : null}
      <p className="infoArch">{el.node.frontmatter.infoArch}</p>

      {el.node.frontmatter.infoDes ? <h4>Design</h4> : null}
      <p className="infoDes"> {el.node.frontmatter.infoDes}</p>

      <div className="spec">
        <div className="exLink">
          <div className="exLinkLine">
            <FaPaperPlane style={{ marginRight: "1rem" }} />
            <a
              href={el.node.frontmatter.siteUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="borderLeftRight gradientText"
            >
              Visit the website.
            </a>
          </div>
          <div className="exLinkLine">
            <FiLayers style={{ marginRight: "1rem" }} />
            <div className="techStack gradientText">
              {el.node.frontmatter.techStack}
            </div>
          </div>

          {el.node.frontmatter.githubUrl ? (
            <div className="exLinkLine">
              <FaGithub style={{ marginRight: "1rem" }} />
              <a
                href={el.node.frontmatter.githubUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="borderLeftRight gradientText"
              >
                Take a look at repo.
              </a>
            </div>
          ) : null}
        </div>

        <a
          href={el.node.frontmatter.siteUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <GatsbyImage
            image={getImage(el.node.frontmatter.screenShot)}
            style={timelineImage}
            alt={el.node.frontmatter.siteName}
          />
        </a>
      </div>
    </TimelineEvent>
  ))

  return (
    <Timeline
      lineColor="#424242"
      style={{
        maxWidth: "800px",
      }}
    >
      {element}
    </Timeline>
  )
}

export default ProjectTimeline

const bubbleStyle = {
  background: "white",
  width: "50px",
  height: "50px",
  border: "1px solid #424242",
  justifyContent: "center",
  alignItems: "center",
  left: "-9px",
  cursor: "default",
}

const titleStyle = {
  marginLeft: "10px",
}

const iconStyle = {
  marginBottom: "0",
}

const contentStyle = {
  boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.09)",
}

const timelineLogo = {
  margin: "0",
  cursor: "default",
}

const timelineImage = {
  borderRadius: "4px",
  margin: "2rem 0 1rem 0",
  boxShadow: "0 14px 28px rgba(0, 0, 0, 0.3), 0 12px 9px rgba(0, 0, 0, 0.1)",
  order: "1",
  cursor: "pointer",
}
