import React from 'react';

const hello = () => {
	return (
		<header>
			<h1>Hello there!</h1>
			<h3 className="swap">
				<span className="swap__visible">please take a look </span>
				<span className="swap__invisible">what has been done </span>
			</h3>
		</header>
	);
};

export default hello;
